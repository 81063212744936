.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.service {
  background: var(--color-bg-variant);
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--color-primary-variant);
  height: fit-content;
  transition: var(--transition-std);
}

.service:hover {
  background: rgba(256, 256, 256, 0);
  border-color: var(--color-primary);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.3);
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list .service__item {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__item-icon {
  color: var(--color-primary);
  /* font-size: 2.4rem; */
}

.service__list p {
  font-size: 0.9rem;
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
  }

  .service {
    height: auto;
  }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 720px) {
  .services__container {
    grid-template-columns: 1fr;
    margin: 1rem auto;
    gap: 1.5rem;
  }
}
